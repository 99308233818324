// .mc-form {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//
//   font-size: 18px;
// }
//
// .mc-input,
// .mc-button {
//   font-size: 1em;
//   border-radius: 0;
//   padding: .5em 1em;
// }
//
// .mc-button {
//   background-color: $theme-color
// }
