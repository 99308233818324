.header {
	position: fixed;
	left: 0;
	top: 0;
	padding: 1em 2em;
	width: 100%;
	z-index: 20;
}

.logo {
	width: 160px;
}

.logo img {
	height: auto;
	width: 100%;
}

.social {
	top: 30px;
	right: 45px;
	font-size: 20px;
	position: absolute;
}

.social i {
	margin-left: 20px;
}

.social a {
	color: #333;
}

#h {
	background: url(../img/header-paint.jpg) no-repeat center top;
	text-align:center;
	padding-top: 80px;
	background-attachment: relative;
	background-position: center center;
	min-height: 700px;
	width: 100%;
	color: white;
	background-size: cover;
	position: relative;

	&::after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;

		background-color: #333;
		opacity: .7;
		z-index: 1;
	}

	> .container {
		position: relative;
		z-index: 2;
	}

	@include media($desktop-breakpoint) {
		padding-top: 180px;
	}
}

#h .logo {
	top: 30px;
	left: 45px;
	font-size: 18px;
	font-weight: 900;
	position:absolute;
}

#h h1 {
	font-weight: 700;
	color: #fff;
	font-size: 40px;
}

#h {
	.subtitle {
		font-size: 1.6em;
		font-weight: 300;
		line-height: 1.4;
		color: #fff;
	}
}
