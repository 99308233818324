/* Import fonts */
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700,900);

* {
	margin: 0;
	padding: 0px;
	font-family: 'Lato', sans-serif;
}
body {
	background: #fff;
	margin: 0;
	color: #5a5a5a;
}

h1, h2, h3, h4, h5, h6 {
	font-family: 'Lato', sans-serif;
	font-weight: 300;
}


p {
	padding: 0;
	margin-bottom: 12px;
    font-family: 'Lato', sans-serif;
	font-weight: 300;
	font-size: 16px;
	line-height: 26px;
	letter-spacing: 1px;
	color: #666;
	margin-top: 10px;
}

html,
body {
	height: 100%;
}

.alignleft { float: left; }
.alignright { float: right; }
.aligncenter {
	margin-left: auto;
	margin-right: auto;
	display: block;
	clear: both;
}
.centered {text-align: center}
.mt {margin-top: 50px;}
.mb {margin-bottom: 50px;}
.mtb {margin-top: 50px; margin-bottom: 50px;}
.mtb2 { margin-top: 100px; margin-bottom: 100px;}
.ptb {padding-top: 80px; padding-bottom: 80px;}

.clear {
	clear: both;
	display: block;
	font-size: 0;
	height: 0;
	line-height: 0;
	width:100%;
}
::-moz-selection  {
	color: #fff;
	text-shadow:none;
	background:#2B2E31;
}
::selection {
	color: #fff;
	text-shadow:none;
	background:#2B2E31;
}
*,
*:after,
*:before {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}
a {
	padding: 0;
	margin: 0;
	text-decoration: none;
	-webkit-transition: background-color .4s linear, color .4s linear;
	-moz-transition: background-color .4s linear, color .4s linear;
	-o-transition: background-color .4s linear, color .4s linear;
	-ms-transition: background-color .4s linear, color .4s linear;
	transition: background-color .4s linear, color .4s linear;
	color: #1abc9c;
}
a:hover,
a:focus {
  text-decoration: none;
  color:#696E74;
}

.nopadding {
	padding: 0px !important;
	margin: 0px;
}


/* FORM CONFIGURATION */

input {
	font-size: 16px;
	min-height: 40px;
	border-radius: 25px;
	line-height: 20px;
	padding: 15px 30px 16px;
	border: 1px solid #b9b9af;
	margin-bottom: 10px;
	background-color: #fff;
	opacity: 0.9;
	-webkit-transition: background-color 0.2s;
	transition: background-color 0.2s;
}

.subscribe-input {
	float: left;
	width: 65%;
	text-align: left;
	margin-right: 2px;
	color: $text-color;
}

@media screen and (max-width: 767px) {
	.subscribe-input {
		width: 100%;
	}
}

.subscribe-submit {
	right: 0;
}

.btn-download {
	background: #FDE3A7;
}

/*Buttons Configuration */
.btn {
	cursor: pointer;
}
.btn-conf {
	border-radius: 50px;
	margin-right: 15px;
	font-size: 11px;
	letter-spacing: 1px;
	text-transform: uppercase;
	padding: 18px 28px 17px 28px;
	}

.btn-conf-2 {
	border-radius: 50px;
	margin-right: 15px;
	font-size: 11px;
	letter-spacing: 1px;
	text-transform: uppercase;
	padding: 12px 28px 11px 28px;
	margin-top: 20px;
	}

.btn-green {
	background: #03C9A9;
	color: white
}
