/* SPONSORS */
#g {
	background: #fff;
	padding-top: 60px;
	padding-bottom: 60px;
}

.sponsor img {
	max-width: 140px;
	max-height: 60px;
	vertical-align: middle;

  filter: grayscale(100%);
}

.sponsor .col-sm-2 {
	height: 80px;
	line-height: 80px;
}
