/* SEP */
#sep {
	background: #1B70E0 url(../img/banner-blue.jpg) no-repeat center top;
	padding-top: 90px;
	text-align:center;
	background-attachment: relative;
	background-position: center center;
	min-height: 450px;
	width: 100%;
	color: white;
  background-size: cover ;
	position: relative;

	&::after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;

		background-color: #1B70E0;
		opacity: .75;
		z-index: 1;
	}

	> .container {
		position: relative;
		z-index: 2;
	}
}

#sep {
	h2,
	h4 {
		color: #fff;
	}
	h2 {
		// color: #fff;
		font-size: 2.4em;
		font-weight: 700;
	}

	h4 {
		// color: #fff;
		font-weight: 600;
		letter-spacing: 1px;
		line-height: 30px;
		margin-top: 30px;
	}
}


#green {
	padding-top: 90px;
	background-color: #03C9A9;
	padding-bottom: 60px;
	min-height: 300px;
	padding-top: 90px;
	text-align:center;
	background-attachment: relative;
	background-position: center center;
	// min-height: 450px;
	width: 100%;
	color: white;
  background-size: auto 90%;
	position: relative;
}

#green.blue {
	background: #03C9A9 url(../img/prodotti/box-no-bg.png) no-repeat center top;
	background-attachment: relative;
	background-position: center center;
	// min-height: 450px;
	width: 100%;
	color: white;
  background-size: auto 90%;

	&::after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;

		background-color: #03C9A9;
		opacity: .75;
		z-index: 1;
	}

	> .container {
		position: relative;
		z-index: 2;
	}

	&,
	&::after {
		background-color:  #FC913A;
	}
}

#green h3 {
	font-weight: 600;
}

#green tgr {
	font-weight: 700;
	letter-spacing: 1px;
}

#green p {
	color: #fff;
}
